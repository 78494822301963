@font-face {
  font-family: Lato;
  src: url("../../public/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: Bellefair;
  src: url("../../public/bellefair/Bellefair-Regular.otf");
}

@font-face {
  font-family: LatoLight;
  src: url("../../public/Lato/Lato-Light.ttf");
}

@keyframes move {
  100% {
    width: -20px;
    height: -20px;
  }
  50% {
    width: 20px;
    height: 20px;

    transform: translate3d(0, 0, 50px);
  }
}
.bubble-container {
  z-index: 0;
  position: absolute;
  min-width: 100vw;
  min-height: 100%;
  transform-style: preserve-3d;
  overflow-x: hidden;
}
.bubble-wrap {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 200px;
  transform-style: preserve-3d;
  transform-origin: center center;
  perspective: 600px;
  overflow-x: hidden;
  min-height: 100%;
  z-index: 0;
}
.bubble {
  position: absolute;
  background: rgba(2, 84, 152, 0.326);
  opacity: 0.7;
  border-radius: 50%;
  animation: move 6s infinite;
}

@for $i from 1 through 100 {
  .bubble:nth-child(#{$i}) {
    $size: random(30) + px;
    height: $size;
    opacity: random(7) * 0.1;
    width: $size;
    animation-delay: -$i * 0.2s;
    animation: move random(20s) infinite alternate-reverse ease-in-out;
    left: random(100) * 1%;
    top: random(100) * 0.4%;
  }
}

html {
  scroll-behavior: smooth;
  font-size: 20px;
  font-family: Lato, "Open Sans", sans-serif;

  p {
    font-weight: 600 !important;
  }

  @media screen and (max-width: 816px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 13px;
  }
}
.navbar-scroll {
  background: #001630ad;
  backdrop-filter: blur(3px);
}
.truncate {
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
